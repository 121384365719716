$(document).ready(function()
{
  /* show alert if session expired */

  $('.bg-alert__session-expired').hide();
  if (window.location.hash === '#session_expired') {
    $('.bg-alert__session-expired').show();
  }

  bg_utils.initCaptchaInViews();

});
